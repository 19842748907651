<!--
View: Performance Introduction
* @FileDescription: 公司能力   
* @Author: ruixiaozi
* @Email: admin@ruixiaozi.com
* @Date: 2021年05月30日 13:58:05
* @Version: 版本
-->
<template>
  <div class="introduction">
    <div class="title-bar">

    </div>
    <div class="content">
      <el-card :body-style="{ padding: '20px', height: 'fit-content' }" class="card">
        <div class="card-body">
          <div class="title">
            <span>轴流压气机</span>
          </div>
          <div class="images">
            <img src="/software/s1.png" class="image">
          </div>
        </div>
      </el-card>
      <el-card :body-style="{ padding: '20px', height: 'fit-content' }" class="card">
        <div class="card-body">
          <div class="title">
            <span>离心压气机</span>
          </div>
          <div class="images">
            <img src="/software/s2.png" class="image">
          </div>
        </div>
      </el-card>
      <el-card :body-style="{ padding: '20px', height: 'fit-content' }" class="card">
        <div class="card-body">
          <div class="title">
            <span>轴流涡轮</span>
          </div>
          <div class="images">
            <img src="/software/s3.png" class="image">
          </div>
        </div>
      </el-card>
      <el-card :body-style="{ padding: '20px', height: 'fit-content' }" class="card">
        <div class="card-body">
          <div class="title">
            <span>向心涡轮</span>
          </div>
          <div class="images">
            <img src="/software/s4.png" class="image">
          </div>
        </div>
      </el-card>
      <el-card :body-style="{ padding: '20px', height: 'fit-content' }" class="card">
        <div class="card-body">
          <div class="title">
            <span>蜗壳</span>
          </div>
          <div class="images">
            <img src="/software/s5.png" class="image">
          </div>
        </div>
      </el-card>


      
    </div>
  </div>
</template>

<script>

export default {
  // View name
  name: 'Software',
  // View props
  props: {

  },
  // Locally registered components
  components: {

  },
  // View status
  data() {
    return {}
  },
  // Calculate attribute
  computed: {

  },
  // View watch
  watch: {

  },
  // View methods
  methods: {

  },
  // Lifecycle hooks
  // 路由切换，重新创建组件，（在这里获取数据）
  beforeRouteEnter(to, from, next) {

    next();
  },
  // 路由参数变化（或者重复点击当前路由导致参数变化），（在这里获取数据）
  beforeRouteUpdate(to, from, next) {

    next();
  },
};
</script>

<style lang="scss" scoped>
.introduction {
  .title-bar {
    width: 100%;
    height: 150px;
    background-image: url('~@/assets/bg.png');
    background-size: cover;
    background-position: center;

  }

  .card {
    margin: 50px auto;
    width: 1000px;
    &-body {
      display: grid;
      grid-template-columns: 20% 1fr;
    }

    .title {
      display: flex;
      font-size: 24px;
      color: #1E90FF;
      justify-content: center;
      align-items: center;
      letter-spacing: 8px;
      font-weight: 700;
      margin-bottom: 20px;
      writing-mode: vertical-lr
    }

    .images {
      height: calc(100% - 50px);
      width: 100%;
      text-align: center;
      
      .image {
        max-width: 100%;
        margin: 8px;
      }

      .main-img {
        display: inline-flex;
        height: 100%;
        align-items: center;
      }
    }

    

    .card-content {

      

      .description {
        font-size: 18px;
        color: #1E90FF;
        padding: 20px 20px;
        line-height: 30px;
      }
    }

  }

  .card:nth-of-type(odd) {
    .main-img  {
      order: 2
    }

    .card-content {
      order: 1
    }
  }
}</style>
